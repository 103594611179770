import React, { useState } from 'react';

function GpwDataPipeline() {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [password, setPassword] = useState('');
  const correctPassword = 'kierat';  // Tutaj wpisz swoje hasło

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = () => {
    if (password === correctPassword) {
      setIsAuthorized(true);
    } else {
      alert('Incorrect password!');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <div style={{ maxWidth: '1200px', margin: 'auto', textAlign: 'left', padding: '20px' }}>
      <h1 style={{ fontSize: '36px', marginBottom: '20px' }}>GPW (WSE) data pipeline</h1>
      <p style={{ fontSize: '24px', lineHeight: '1.6', marginBottom: '40px' }}>
        It fetches the data from gpw.pl/akcje, transforms it and presents it on a dashboard. Everything done on Google Cloud Platform.
      </p>
      <div>
        <h2 style={{ fontSize: '28px', marginBottom: '20px' }}>Access Dashboard</h2>
        {!isAuthorized ? (
          <div>
            <input
              type="password"
              value={password}
              onChange={handlePasswordChange}
              onKeyDown={handleKeyDown}
              placeholder="Password"
              style={{ margin: '10px 0', padding: '8px', width: '200px' }}
            />
            <button onClick={handleLogin} style={{ padding: '8px 16px' }}>Access</button>
          </div>
        ) : (
          <div>
            <iframe
              src="https://lookerstudio.google.com/embed/reporting/0c962557-fc5d-4558-b117-81d5c10de542/page/Zb11D"  // Wstaw URL do swojego dashboardu Looker Studio
              width="100%"
              height="600px"
              style={{ border: 'none' }}
              title="GPW Data Pipeline Dashboard"
            ></iframe>
          </div>
        )}
      </div>
    </div>
  );
}

export default GpwDataPipeline;
