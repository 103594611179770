import React from 'react';
import { Link } from 'react-router-dom';

function Projects() {
  return (
    <div style={{ maxWidth: '1200px', margin: 'auto', textAlign: 'left', padding: '20px' }}>
      <h1 style={{ fontSize: '40px', marginBottom: '20px' }}>Check out the projects I have worked on in my private time!</h1>
      <ul style={{ fontSize: '28px', lineHeight: '1.6', listStyle: 'none', padding: 0 }}>
        <li style={{ marginBottom: '20px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '10px', fontSize: '36px', lineHeight: '1' }}>•</span>
            <Link to="/projects/gpw-data-pipeline" style={{ color: '#61dafb', textDecoration: 'none', fontSize: '34px' }}>GPW (WSE) data pipeline</Link>
          </div>
          <p style={{ marginLeft: '46px', color: '#ccc' }}>Fetches data from gpw.pl/akcje, transforms it, and presents it on a dashboard using Google Cloud Platform.</p>
        </li>
        {/* Dodaj więcej projektów tutaj */}
      </ul>
    </div>
  );
}

export default Projects;
