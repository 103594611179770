import React, { useState } from 'react';
import logo from '../assets/rs-logo.png';  // Aby zaimportować logo

const AuthGate = ({ children }) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [password, setPassword] = useState('');
  const correctPassword = 'lucka';  // Tutaj wpisz swoje hasło

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = () => {
    if (password === correctPassword) {
      setIsAuthorized(true);
      sessionStorage.setItem('isAuthorized', 'true');  // Zapisz autoryzację w pamięci sesji
    } else {
      alert('Incorrect password!');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  if (!isAuthorized) {
    return (
      <div style={{ padding: 20, textAlign: 'center', backgroundColor: 'black' }}>
        <img src={logo} alt="Logo RS" style={{ width: 150, margin: '20px auto' }} />
        <h1 style={{ color: 'white' }}>Enter password to continue</h1>
        <input
          type="password"
          value={password}
          onChange={handlePasswordChange}
          onKeyDown={handleKeyDown}
          placeholder="Password"
          style={{ margin: '10px 0', padding: '8px', width: '200px' }}
        />
        <button onClick={handleLogin} style={{ padding: '8px 16px' }}>Access</button>
      </div>
    );
  }

  return children;  // Renderuj dzieci, gdy użytkownik jest zautoryzowany
};

export default AuthGate;
