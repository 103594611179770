import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Projects from './components/Projects';
import GpwDataPipeline from './components/GpwDataPipeline';
import AuthGate from './components/AuthGate';
import './styles/App.css';

function App() {
  return (
    <Router>
      <AuthGate>
        <div className="App">
          <nav>
            <div className="nav-container">
              <div className="nav-left">
                <div className="name">Robert Szydlik</div>
              </div>
              <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/about">About</Link></li>
                <li><Link to="/projects">Projects</Link></li>
              </ul>
            </div>
          </nav>
          <div className="content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/projects/gpw-data-pipeline" element={<GpwDataPipeline />} />
            </Routes>
          </div>
          <footer className="footer">
            &copy; Robert Szydlik 2024
          </footer>
        </div>
      </AuthGate>
    </Router>
  );
}

export default App;
