import React from 'react';
import '../styles/App.css'; // Załaduj styl z App.css

function Home() {
  return (
    <div style={{ textAlign: 'center', padding: '80px 20px' }}>      
      <h2 style={{ fontSize: '46px', marginBottom: '30px' }}>Nice to meet you!</h2>
      <p style={{ fontSize: '34px', lineHeight: '1.6', marginBottom: '80px' }}>
        I am a Junior Data Engineer at Citi, driven by ambition and a passion for data analytics.
      </p>
      <h3 style={{ fontSize: '36px', marginBottom: '20px' }}>Contact me:</h3>
      <div className="social-links" style={{ marginBottom: '40px' }}>
        <a href="https://linkedin.com/in/robertszydlik" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-linkedin"></i>
        </a>
        <a href="https://github.com/robertszydlik" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-github"></i>
        </a>
        <a href="mailto:robert.szydlik1@gmail.com">
          <i className="fas fa-envelope"></i>
        </a>
      </div>
      <a href="my-react-portfolio/public/rob_szydlik_cv_final.pdf" download="Robert_Szydlik_Resume.pdf">
        <button className="download-button">Download Resume</button>
      </a>
    </div>
  );
}

export default Home;
