import React from 'react';

function About() {
  return (
    <div style={{ maxWidth: '1200px', margin: 'auto', textAlign: 'justify', padding: '20px' }}>
      <h1 style={{ fontSize: '46px', marginBottom: '20px' }}>About Me</h1>
      <p style={{ fontSize: '34px', lineHeight: '1.6', marginBottom: '80px' }}>
        I am an aspiring data engineer currently working in the risk department of a major financial corporation.
        My daily tasks revolve around handling extensive data sets and driving innovative data solutions that influence
        strategic decisions.
      </p>
      <p style={{ fontSize: '34px', lineHeight: '1.6' }}>
        Highly motivated and passionate about data analytics, I continuously seek to challenge myself and expand my expertise
        in the field of data science and engineering, aiming to contribute significantly to technological advancement in data
        management and analysis.
      </p>
    </div>
  );
}

export default About;
